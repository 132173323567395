import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';

import styles from './WhatsNewComponent.module.css';
import Header from './common/Header';
import Footer from './common/Footer';
import defaultImage from '../assets/images/tvbase.png';
import active from '../assets/images/scrollActive.png';
import inActive from '../assets/images/scrollInActive.png';
import current from '../assets/images/scrollCurrent.png';
import DayPicker from 'react-day-picker';
import whatsnew from '../assets/images/new.png';
import { DateString } from '../utils';
import QuotesLoader from './common/QuotesLoader';
import 'react-day-picker/lib/style.css';

import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';

WhatsNewComponent.propTypes = {
  newObj: PropTypes.object,
  profile: PropTypes.object,
  logout: PropTypes.func
};
export default function WhatsNewComponent({newObj, profile, logout}) {
  const [ selectedDay, setSelectDay ] = useState(null);
  const { details, loading, error } = newObj;
  const history = useHistory();
  const dateArr = getDaysArray(profile);
  const handleDayClick = day => {
    const dateString = DateString(day);
    setSelectDay(day);
    history.push(`/whatsnew/${dateString}`);
  };

  return (
    <Container fluid={true} className={`${styles.rec} rec wnew`}>
      <Header />
      { details && <Navbar profile={profile} logout={logout}/> }
      <Container fluid={true}>
        {loading &&
          <div className="fullHeight">
            <QuotesLoader />
          </div>
        }
      </Container>
      { details &&
        <Row className={`${styles.bgBase} wnew__bgBase`}>
          <Col xs={12} sm={12} md={12} lg={8} className="pt-0 pr-0 wnew__holder">
            {error && 'Something went wrong'}
            {details && <CalendarView details={details} dateArr={dateArr} />}
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} className="d-none d-lg-block">
            {details && <DayPicker
              className="wnew__customCalendar w-100 shadow-sm card"
              selectedDays={selectedDay}
              onDayClick={handleDayClick}
              initialMonth={new Date()}
              fromMonth={new Date(Object.keys(details)[0])}
              disabledDays={day => day <= (new Date(dateArr[0]))
                || day >= (new Date(dateArr[dateArr.length-1]))}
            />}
          </Col>
        </Row>
      }
      <Footer />
    </Container>
  );
}

Navbar.propTypes = {
  profile: PropTypes.object,
  logout: PropTypes.func
};
function Navbar({profile, logout}) {
  const history = useHistory();

  const logoutWatchNXT = () => {
    logout();
    history.push('/');
  };
  return (
    <Row className={`${styles.recbody} wnew__body`}>
      <Col className={`${styles.recnav} wnew__nav d-flex align-items-center`}>
        <Col lg={12} className="wnew__userinfo d-flex align-items-center">
          <img
            src={whatsnew}
            alt="profile"
            className={`${styles.__profile} wnew__profileImg `}
          />
          <h3 className={`${styles.pageTitle} pageTitle mb-0`}>Calendar</h3>
          { !profile &&
            <span className='login' onClick={() => history.push('/login')}><h3>Login</h3></span>
          }
          { profile &&
            <span className='login' onClick={logoutWatchNXT}><h3>Logout</h3></span>
          }
        </Col>
      </Col>
    </Row>
  );
}

CalendarView.propTypes = {
  details: PropTypes.object,
  dateArr: PropTypes.array
};
export function CalendarView({details, dateArr}) {
  return (
    <Card className={`${styles.__cHolder} mb-4 shadow-sm`}>
      <Card.Body className="pt-0 wnew__mremovep">
        <Table responsive className="wnew__table mb-0">
          <tbody>
            <tr>
              <td className={`${styles.__w12} ${styles.__actionLabel}`}>&nbsp;</td>
            </tr>
            {dateArr.map(thisDay => <ScrollTr key={thisDay} thisDay={thisDay} detail={details[thisDay]} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}

ScrollTr.propTypes = {
  thisDay: PropTypes.string,
  detail: PropTypes.array
};
export function ScrollTr({thisDay, detail}) {
  const weekDay = new Date(thisDay).toLocaleString('en-us', {weekday:'long'});
  const monthLong = new Date(thisDay).toLocaleString('en-us', {month:'long'});
  const thisDate = new Date(thisDay).toLocaleString('en-us', {day:'numeric'});
  const { month, day, year } = useParams();
  const currentDay = new Date(thisDay).toDateString() === new Date().toDateString();
  const calanderActiveDay = thisDay == `${month}/${day}/${year}`;
  const noActivityDay = !detail;
  const dayImage = currentDay ?
    current :
    calanderActiveDay
      ? active :
      noActivityDay ?
        inActive :
        defaultImage;
  useEffect(() => {
    if (month && day && year && detail && document.getElementById(`${month}/${day}/${year}`)) {
      if(document.getElementsByClassName('wnew__bgBase')[0].offsetHeight < 750){
        document.getElementsByClassName('card')[0].scrollTo({ top: document.getElementById(`${month}/${day}/${year}`).offsetTop - 55, behavior: 'smooth' });
      }
      else{
        window
          .scrollTo({ top: document.getElementById(`${month}/${day}/${year}`).offsetTop - 55, behavior: 'smooth' });
      }
    }
  }, [month, day, year]);
  return (
    <tr
      id={thisDay}
      className={
        `${currentDay ? 'calandarCurrent' : ''}
        ${calanderActiveDay ? 'calanderActive' : ''}
        ${noActivityDay ? 'noActivityDay' : ''}`
      }
    >
      <td className={`${styles.__w12} ${styles.__actionLabel}`}>
        <span className={`${styles.__dateInfo} ${styles.__tvactive} wnew__dateInfo`}>
          <span className="pr-1 wnew__lweek">{weekDay}</span>
          <span className="pr-1 wnew__lday">{`${monthLong} ${thisDate}`}</span>
          <img src={dayImage} alt="activeImage" className={styles.__activeImg}/>
        </span>
      </td>
      <td className={`${styles.__w88} pt-1`}>
        {detail && detail.map(obj => (
          <div key={obj.id} className={`${styles.tbg} rowSet`}>
            <span className='titleSet'>
              <EllipsisWithTooltip placement="bottom"><span>{obj.display_name || obj.name}</span></EllipsisWithTooltip>
            </span>
            <ul className="flags">
              <li><span><EllipsisWithTooltip placement="bottom">{obj.entity_type}</EllipsisWithTooltip></span></li>
              <li><span><EllipsisWithTooltip placement="bottom">{obj.genre || ''}</EllipsisWithTooltip></span></li>
              <li><span><EllipsisWithTooltip placement="bottom">{obj.channel || ''}</EllipsisWithTooltip></span></li>
            </ul>
          </div>
        ))}
      </td>
    </tr>
  );
}

function getDaysArray(profile) {
  const start = new Date();
  const end = new Date();
  if (profile) {
    start.setMonth(start.getMonth() - 1);
    end.setMonth(end.getMonth() + 3);
  } else {
    start.setDate(start.getDate() - 7);
    end.setDate(end.getDate() + 21);
  }
  for(var arr=[],dt=start; dt<=end; dt.setDate(dt.getDate()+1)){
    const dateString = DateString(dt);
    arr.push(`${dateString}`);
  }
  return arr;
}
