import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';

import MostPopularComponent from '../components/MostPopularComponent';
import { getTreanding } from '../actions';
import { trackGA } from '../utils';
import { MOST_POPULAR } from '../constants/modules';

MostPopular.propTypes = {
  trendingDetails: PropTypes.object,
  getTreanding: PropTypes.func
};

function MostPopular({ trendingDetails, getTreanding }) {
  const location = useLocation();
  useEffect(() => {
    trackGA(MOST_POPULAR, location);
  }, [location]);
  useEffect(() => getTreanding(), []);
  return (
    <MostPopularComponent
      trendingDetails={trendingDetails}
    />
  );
}

const mapStateToProps = (state) => {
  const { trendingReducer } = state;
  console.log('trendingReducer ===> ', trendingReducer);
  return {
    trendingDetails: trendingReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTreanding: () => dispatch(getTreanding())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MostPopular);
