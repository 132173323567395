import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';

import styles from './WhatsNewCalendarComponent.module.css';
import Header from './common/Header';
import Footer from './common/Footer';
import DayPicker from 'react-day-picker';
import whatsnew from '../assets/images/new.png';
import { DateString } from '../utils';
import 'react-day-picker/lib/style.css';
import './Calendar.css';

import HorizontalDayPicker from './common/HorizontalDayPicker';

WhatsNewCalendarComponent.propTypes = {
  newObj: PropTypes.object,
  profile: PropTypes.object,
  logout: PropTypes.func
};
export default function WhatsNewCalendarComponent({newObj, profile, logout}) {
  const [ selectedDay, setSelectDay ] = useState(null);
  const { details, loading, error } = newObj;
  const history = useHistory();
  const dateArr = getDaysArray(profile);
  const handleDayClick = day => {
    const dateString = DateString(day);
    setSelectDay(day);
    history.push(`/calendar/${dateString}`);
  };

  return (
    <Container fluid={true} className={`${styles.rec} rec wnew cnew`}>
      <Header />
      { details && <Navbar profile={profile} logout={logout}/> }
      { loading &&
        <Container fluid={true} className={styles.calendarLoading}>
          <div>
            <Spinner animation="border" />
          </div>
        </Container>
      }
      { details &&
      <>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} className="d-lg-none p-0">
            <HorizontalDayPicker dateArr={dateArr} selectedDay={selectedDay} handleDayClick={handleDayClick}/>
          </Col>
        </Row>
        <Row className={`${styles.bgBase} wnew__bgBase`}>
          <Col xs={12} sm={12} md={12} lg={8} className="pt-0 pr-0 wnew__holder">
            {error && 'Something went wrong'}
            {details && <CalendarView details={details} dateArr={dateArr} className="wnew__customCalendar w-100 shadow-sm card" />}
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} className="d-none d-lg-block">
            {details && <DayPicker
              className="wnew__customCalendar w-100 shadow-sm card"
              selectedDays={selectedDay}
              onDayClick={handleDayClick}
              initialMonth={new Date()}
              fromMonth={new Date(Object.keys(details)[0])}
              disabledDays={day => day <= (new Date(dateArr[0]))
                || day >= (new Date(dateArr[dateArr.length-1]))}
            />}
          </Col>
        </Row>
      </>
      }
      <Footer />
    </Container>
  );
}

Navbar.propTypes = {
  profile: PropTypes.object,
  logout: PropTypes.func
};
function Navbar({profile, logout}) {
  const history = useHistory();

  const logoutWatchNXT = () => {
    logout();
    history.push('/');
  };
  return (
    <Row className={`${styles.recbody} wnew__body cnav`}>
      <Col className={`${styles.recnav} wnew__nav d-flex align-items-center`}>
        <Col lg={12} className="wnew__userinfo d-flex align-items-center">
          <img
            src={whatsnew}
            alt="profile"
            className={`${styles.__profile} wnew__profileImg `}
          />
          <h3 className={`${styles.pageTitle} pageTitle mb-0`}>Calendar</h3>
          { !profile &&
            <span className='login' onClick={() => history.push('/login')}><h3>Login</h3></span>
          }
          { profile &&
            <span className='login' onClick={logoutWatchNXT}><h3>Logout</h3></span>
          }
        </Col>
      </Col>
    </Row>
  );
}

CalendarView.propTypes = {
  details: PropTypes.object,
  dateArr: PropTypes.array
};
export function CalendarView({details, dateArr}) {
  return (
    <Card className={`${styles.__cHolder} mb-4 shadow-sm`}>
      <Card.Body className="pt-0 wnew__mremovep details-view">
        <div className="calendar-list nCalendarList">
          {dateArr.map(thisDay => <ScrollTr key={thisDay} thisDay={thisDay} detail={details[thisDay]}/>)}
        </div>
      </Card.Body>
    </Card>
  );
}

ScrollTr.propTypes = {
  thisDay: PropTypes.string,
  detail: PropTypes.array,
  cardRef: PropTypes.any
};
export function ScrollTr({thisDay, detail}) {
  const weekDay = new Date(thisDay).toLocaleString('en-us', {weekday:'long'});
  const monthLong = new Date(thisDay).toLocaleString('en-us', {month:'long'});
  const thisDate = new Date(thisDay).toLocaleString('en-us', {day:'numeric'});
  const { month, day, year } = useParams();
  const monthAndDate = `${monthLong} ${thisDate}`;

  useEffect(() => {
    if (month && day && year && detail && document.getElementById(`${month}/${day}/${year}`)) {
      if(document.getElementsByClassName('wnew__bgBase')[0].offsetHeight < 750){
        document.getElementsByClassName('card')[0].scrollTo({ top: document.getElementById(`${month}/${day}/${year}`).offsetTop - 55, behavior: 'smooth' });
        document.getElementsByClassName('nCalendarList')[0].scrollTo({ top: document.getElementById(`${month}/${day}/${year}`).offsetTop - 60, behavior: 'smooth' });
      }
      else{
        window
          .scrollTo({ top: document.getElementById(`${month}/${day}/${year}`).offsetTop - 55, behavior: 'smooth' });
      }
    }
  }, [month, day, year]);
  return (
    <div className="calendar-day" id={thisDay}>
      <div className='detail-month'>{monthAndDate.toLowerCase()}</div>
      <div className='detail-day'>{weekDay.toLowerCase()}</div>
      {detail && detail.map(obj => {
        const typeNetwork = (obj.entity_type && obj.channel) ? `${obj.entity_type} / ${obj.channel}` : obj.entity_type ? obj.entity_type : obj.channel;
        return (
          <div key={obj.id} className="entry">
            <div className='detail-name'>{obj.display_name || obj.name}</div>
            <p className='detail-network'>{typeNetwork}</p>
          </div>
        );
      })}
    </div>
  );
}

function getDaysArray(profile) {
  const start = new Date();
  const end = new Date();
  if (profile) {
    start.setMonth(start.getMonth() - 1);
    end.setMonth(end.getMonth() + 3);
  } else {
    start.setDate(start.getDate() - 7);
    end.setDate(end.getDate() + 21);
  }
  for(var arr=[],dt=start; dt<=end; dt.setDate(dt.getDate()+1)){
    const dateString = DateString(dt);
    arr.push(`${dateString}`);
  }
  return arr;
}
