import PropTypes from 'prop-types';
import React from 'react';
import styles from './HorizontalDayPicker.module.css';

const isSameDay = (date1, date2) => new Date(date1).toISOString().split('T')[0] === new Date(date2).toISOString().split('T')[0];

const HorizontalDayPicker = ({ dateArr, selectedDay, handleDayClick }) => {
  const getScroll = (date) => {
    handleDayClick(date);
    // const targetElement = document.getElementById('hscroll_11/02/2024');
    // const daysScrollContainer = document.getElementById('hDatePicker');
    // const targetOffset = targetElement.offsetLeft - daysScrollContainer.offsetLeft;
    // document.getElementById('hDatePicker').scrollTo({
    //   left: targetOffset,
    //   behavior: 'smooth',
    // });
  };

  return (
    <div className={`${styles.horizontal_date_picker} hDatePicker`}>
      <div className={`${styles.date_scroll}`}>
        {dateArr.map((day) => (
          <div
            key={day}
            id={`hscroll_${day}`}
            className={`${styles.date_pill} ${isSameDay(selectedDay, day) ? styles.selected : ''}`}
            onClick={() => getScroll(new Date(day))}
          >
            <div className={`${styles.day}`}>
              {new Date(day).toLocaleDateString('en-us', { day: 'numeric' })}
            </div>
            <div className={`${styles.weekday}`}>
              {new Date(day).toLocaleString('en-us', { weekday: 'short' })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

HorizontalDayPicker.propTypes = {
  dateArr: PropTypes.array,
  selectedDay: PropTypes.instanceOf(Date),
  handleDayClick: PropTypes.func
};

export default HorizontalDayPicker;
