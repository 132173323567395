export const LOGIN = 'Login';
export const HOME = 'Home';
export const RECOMMENDATION = 'Recommendation';
export const SHOW_ME_SOMETHING = 'Show Me Something';
export const TRENDING = 'Trending';
export const MOST_POPULAR = 'Most Popular';
export const WHATS_NEW = 'Whats New';





