import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { ROOT_ROUTE } from '../constants/auth';
import { DateString } from '../utils';

LoginCallback.propTypes = {
  location: PropTypes.object,
  isLoggedIn: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  nextPath: PropTypes.string
};

function LoginCallback() {
  return (
    <Redirect to={`${ROOT_ROUTE}/calendar/${DateString(new Date())}`} />
  );
}

const mapStateToProps = state => {
  const {isLoggedIn, nextPath} = state.user;
  return {
    isLoggedIn,
    nextPath
  };
};

export default connect(mapStateToProps)(LoginCallback);
